import React from "react";

function Footer() {
  return (
    <div>
      <section id="credits" className="py-8">
        <div className="max-w-5xl mx-auto px-6 lg:px-0">
          <div className="w-full text-center">
            <span className="text-sm font-fira text-theme-gray-deep hover:text-theme-aquamarine hover:cursor-pointer ">
              Developed by Ali Ahmed
            </span>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Footer;
