import React, { useState } from "react";
import venueMonk from "./assets/projects/venueMonk.PNG";
import getMyBoat from "./assets/projects/getMyBoat.PNG";
import techRow from "./assets/projects/techRow.PNG";
import projects from "./ProjectsApi";

function Projects() {
  const [allProjects, setallProjects] = useState(projects.allProjects);

  function funcForProjects(projectName) {
    switch (projectName) {
      case "React":
        let react = projects.allProjects.filter((ele) => {
          return ele.lang1 === "React.Js";
        });
        setallProjects(react);
        break;
      case "Node":
        let node = projects.allProjects.filter((ele) => {
          return ele.lang1 === "Node.Js";
        });
        setallProjects(node);
        break;
      case "Next":
        let Next = projects.allProjects.filter((ele) => {
          return ele.lang1 === "Next.Js";
        });
        setallProjects(Next);
        break;
      case "All":
        setallProjects(projects.allProjects);

        break;

      default:
        break;
    }
  }

  return (
    <div id="portfolio">
      <header
        className="md:ml-[11.5vw] lg:ml-[7vw] xl:ml-[12vw] xsm:ml-[16vw] xxsm:ml-[5vw]"
        data-aos="fade-down"
        data-aos-duration="2000"
      >
        <h3 className="text-2xl font-poppins text-theme-gray-light">
          <span className="text-theme-aquamarine text-2xl font-fira mr-3">
            03.
          </span>
          <span className="font-bold relative font-Atkinson text-3xl">
            Portfolio
            <div className="w-[15vw] mb-12  border absolute md:left-36 xxsm:left-32 top-5 -z-9"></div>{" "}
          </span>
        </h3>
      </header>
      <section id="noteworthy-projects" className="py-20">
        {/* 1st featured project */}
        <div
          className="flex flex-wrap items-center mb-6 sm:mb-32 w-[80%] mx-auto"
          data-aos="fade-down"
          data-aos-duration="2000"
        >
          <div className="w-full sm:w-2/5 z-10">
            <div className="text-left mb-4">
              <p className="text-theme-aquamarine text-sm font-poppins">
                Featured Project
              </p>
              <a
                href="https://www.venuemonk.com/"
                className="text-theme-gray-light text-2xl font-poppins font-extrabold hover:text-theme-aquamarine"
                target="_blank"
                rel="noopener noreferrer"
              >
                Venue Monk
              </a>
            </div>
            <div className="bg-theme-blue-base rounded p-6 shadow-xl mr-0 sm:-mr-16 mb-6">
              <p className="text-theme-gray-deep leading-relaxed">
                VenueMonk is a top online venue booking portal in Delhi-NCR. They connect customers with the perfect venues for their events, ensuring a seamless booking experience. With a personalized approach, VenueMonk guides customers from selection to booking, making online venue booking as easy as offline. Your go-to platform for hassle-free event planning in the region.
              </p>
            </div>
            <div className="text-left">
              <div className="flex justify-start mb-6">
                <span className="bg-[#112240] px-3 py-2 rounded text-theme-aquamarine    ">
                  Node.js
                </span>
                <span className="bg-[#112240] px-3 py-2 rounded text-theme-aquamarine   ml-2 ">
                  React.js
                </span>
                <span className="bg-[#112240] px-3 py-2 rounded text-theme-aquamarine  ml-2 hover:bg-[#112240]">
                  Tailwind CSS
                </span>
              </div>
            </div>
          </div>
          <div className="w-full sm:w-3/5 mb-4 sm:mb-0 order-first sm:order-last">
            <img
              src={venueMonk}
              className="rounded shadow grayscale hover:grayscale-0 duration-300"
              alt=""
            />
          </div>
        </div>

        {/* 2nd feature project  */}
        <div
          className="flex flex-wrap items-center mb-6 sm:mb-32 w-[80%] mx-auto"
          data-aos="fade-down"
          data-aos-duration="2000"
        >
          <div className="flex flex-wrap items-center">
            <div className="w-full sm:w-3/5 mb-4 sm:mb-0">
              <img src={getMyBoat}
                className="rounded shadow grayscale hover:grayscale-0 duration-300 grayscale-100"
                alt="" />
            </div>
            <div className="w-full sm:w-2/5 z-20">
              <div className="text-right mb-4">
                <p className="text-theme-aquamarine text-sm font-poppins">
                  Featured Project
                </p>
                <a
                  href="https://www.getmyboat.com/"
                  className="text-theme-gray-light text-2xl font-poppins font-extrabold hover:text-theme-aquamarine"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Get My Boat
                </a>
              </div>
              <div className="bg-theme-blue-base rounded p-6 shadow-xl ml-0 sm:-ml-16 mb-6">
                <p className="text-theme-gray-deep leading-relaxed">
                  GetMyBoat is the premier global marketplace for boat rentals and water experiences. Choose from a wide range of watercraft and activities to plan your ideal aquatic adventure. From kayaks, sailboats, and motorboats to yachts, jet skis, fishing charters, and more, GetMyBoat offers over 150,000 listings in 184 countries and 9,300 destinations for unforgettable maritime experiences.
                </p>
              </div>
              <div className="text-right">
                <div className="flex justify-end mb-6">
                  <span className="bg-[#112240] px-3 py-2 rounded text-theme-aquamarine    ">
                    Node.js
                  </span>
                  <span className="bg-[#112240] px-3 py-2 rounded text-theme-aquamarine   ml-2 ">
                    React.js
                  </span>
                  <span className="bg-[#112240] px-3 py-2 rounded text-theme-aquamarine  ml-2 hover:bg-[#112240]">
                    Tailwind CSS
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* 3rd featured project  */}
        <div
          className="flex flex-wrap items-center mb-6 sm:mb-32 w-[80%] mx-auto"
          data-aos="fade-down"
          data-aos-duration="2000"
        >
          <div className="w-full sm:w-2/5 z-10">
            <div className="text-left mb-4">
              <p className="text-theme-aquamarine text-sm font-poppins">
                Featured Project
              </p>
              <a
                href="https://www.techrow.org/"
                className="text-theme-gray-light text-2xl font-poppins font-extrabold hover:text-theme-aquamarine"
                target="_blank"
                rel="noopener noreferrer"
              >
                TechRow
              </a>
            </div>
            <div className="bg-theme-blue-base rounded p-6 shadow-xl mr-0 sm:-mr-16 mb-6">
              <p className="text-theme-gray-deep leading-relaxed">
                Techrow, a leading EdTech company that is revolutionizing the future of traditional classrooms. Their approach involves integrating cutting-edge virtual reality technology into the classroom environment, empowering teachers with new teaching methodologies, and unlocking the potential of disconnected communities.
              </p>
            </div>
            <div className="text-left">
              <div className="flex justify-start mb-6">
                <span className="bg-[#112240] px-3 py-2 rounded text-theme-aquamarine  ">
                  React.js
                </span>
                <span className="bg-[#112240] px-3 py-2 rounded text-theme-aquamarine  ml-2 hover:bg-[#112240]">
                  Tailwind CSS
                </span>
              </div>
            </div>
          </div>
          <div className="w-full sm:w-3/5 mb-4 sm:mb-0 order-first sm:order-last">
            <img
              src={techRow}
              className="rounded shadow grayscale hover:grayscale-0 duration-300"
              alt=""
            />
          </div>
        </div>
        <div className="max-w-5xl mx-auto px-6 lg:px-0">
          <header className="mb-16 text-center">
            <div
              className="font-fira text-sm"
              data-aos="fade-down"
              data-aos-duration="2000"
            >
              <button
                className="bg-[#112240] px-4 py-2 rounded text-theme-aquamarine border-theme-aquamarine border ml-2 "
                onClick={() => {
                  funcForProjects("All");
                }}
              >
                All
              </button>
              <button
                className="bg-theme-blue-deep px-4 py-2 rounded text-theme-aquamarine border-theme-aquamarine border ml-2 my-2 hover:bg-[#112240]"
                onClick={() => {
                  funcForProjects("React");
                }}
              >
                React.Js
              </button>
              <button
                className="bg-theme-blue-deep px-4 py-2 rounded text-theme-aquamarine border-theme-aquamarine border ml-2 hover:bg-[#112240]"
                onClick={() => {
                  funcForProjects("Next");
                }}
              >
                Next.js
              </button>
              <button
                className="bg-theme-blue-deep px-4 py-2 rounded text-theme-aquamarine border-theme-aquamarine border ml-2 hover:bg-[#112240]"
                onClick={() => {
                  funcForProjects("Node");
                }}
              >
                Node.Js
              </button>
            </div>
          </header>

          <div
            className="flex flex-wrap mb-20  mx-auto md:w-[85%] lg:w-[88%] xl:w-full scroll-smooth duration-700 ease-in"
            data-aos="fade-down"
            data-aos-duration="2000"
          >
            {/*noteworthy-project item start */}
            {allProjects.map((obj, ind) => {
              return (
                <div
                  key={ind}
                  className=" w-full xsm:w-[70%] xxsm:mx-auto md:w-[48.5%] lg:w-[32%] relative group ml-4 mt-4  h-64 overflow-hidden duration-300 cursor-pointer"
                >
                  <div className=" h-full w-full rounded-t-md md:h-[72%] xxsm:h-[72%] absolute xxsm:-top-full xsm:-top-48 opacity-50  bg-black group-hover:top-0 group-hover:duration-300 flex items-center justify-center ">
                    <a
                      href={obj.link}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <svg
                        stroke="currentColor"
                        fill="currentColor"
                        strokeWidth="0"
                        viewBox="0 0 16 16"
                        className=" text-theme-gray-light text-4xl hover:text-[#29ffcd] duration-300 hover:mt-4 "
                        height="1em"
                        width="1em"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z"></path>
                        <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z"></path>
                      </svg>
                    </a>
                  </div>

                  <img
                    src={obj.thumb}
                    alt="sapphire"
                    className="rounded-t-md  xxsm:h-[72%] w-full "
                  />
                  <div className="h-full w-full overflow-hidden">
                    <div className="font-fira text-sm bg-theme-blue-base  group-hover:mt-[0px] h-16 -mt-16 duration-300 py-5">
                      <button className="cursor-text bg-[#112240] px-3 py-2 rounded text-theme-aquamarine   ml-2 ">
                        {obj.lang1}
                      </button>
                      <button className="cursor-text bg-[#112240] px-3 py-2 rounded text-theme-aquamarine  ml-2 hover:bg-[#112240]">
                        {obj.lang2}
                      </button>
                    </div>
                    <div className="bg-theme-blue-base rounded-b-md shadow-lg p-3">
                      <h1 className="text-theme-gray-light text-lg">
                        {obj.title}
                      </h1>
                      <p className="text-theme-gray-deep text-sm">{obj.des}</p>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
    </div>
  );
}

export default Projects;
