import React, { useState ,useEffect} from "react";
import About from "./About";
import Contact from "./Contact";
import Experience from "./Experience";
import Footer from "./Footer";
import Hero from "./Hero";
import Loader from "./Loader";
import Nav from "./Nav";
import Projects from "./Projects";
import AOS from 'aos';
import 'aos/dist/aos.css';

function App() {
  const [forLoader, setforLoader] = useState(false);

  useEffect(() => {
    setforLoader(true)
  setTimeout(() => {
    setforLoader(false)

  }, 5000)
  }, [])
  
  AOS.init({once: true})

  return (
    
    <div className="bg-theme-blue-deep scroll-smooth">
      {
        forLoader? 
      <Loader/>
      :

      <div>

      <Nav />
      <Hero />
      <About />
      <Experience />
      <Projects />
      <Contact />
      <Footer />
      </div>
      }
    </div>
  );
}

export default App;
