import React from "react";
import google from "../src/assets/images/google.png"

function Hero() {
  return (
    <div>
      <div className="  font-fira  text-theme-gray-deep  text-3xl  transition duration-200 ease-in fixed bottom-0 md:left-12 lg:left-[4%] w-0 xxsm:hidden sm:block  sm:left-8 ">
        <p className="sm:bottom-24 sm:left-2 absolute">
          <a
            href="https://github.com/aleebaba3594"
            target="_blank"
            rel="noopener noreferrer"
          >
            <svg
              stroke="currentColor"
              fill="none"
              strokeWidth="2"
              viewBox="0 0 24 24"
              strokeLinecap="round"
              strokeLinejoin="round"
              className=" hover:text-theme-aquamarine  -ml-[19px] mb-4 hover:scale-110 hover:ease-in duration-200 "
              height="1em"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M9 19c-5 1.5-5-2.5-7-3m14 6v-3.87a3.37 3.37 0 0 0-.94-2.61c3.14-.35 6.44-1.54 6.44-7A5.44 5.44 0 0 0 20 4.77 5.07 5.07 0 0 0 19.91 1S18.73.65 16 2.48a13.38 13.38 0 0 0-7 0C6.27.65 5.09 1 5.09 1A5.07 5.07 0 0 0 5 4.77a5.44 5.44 0 0 0-1.5 3.78c0 5.42 3.3 6.61 6.44 7A3.37 3.37 0 0 0 9 18.13V22"></path>
            </svg>
          </a>
          <a
            href="https://twitter.com/aleebaba3594"
            target="_blank"
            rel="noopener noreferrer"
          >
            <svg
              stroke="currentColor"
              fill="none"
              strokeWidth="2"
              viewBox="0 0 24 24"
              strokeLinecap="round"
              strokeLinejoin="round"
              className=" hover:text-theme-aquamarine  -ml-[19px] mb-4 hover:scale-110 hover:ease-in duration-200"
              height="1em"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M23 3a10.9 10.9 0 0 1-3.14 1.53 4.48 4.48 0 0 0-7.86 3v1A10.66 10.66 0 0 1 3 4s-4 9 5 13a11.64 11.64 0 0 1-7 2c9 5 20 0 20-11.5a4.5 4.5 0 0 0-.08-.83A7.72 7.72 0 0 0 23 3z"></path>
            </svg>
          </a>
          <a
            href="https://www.linkedin.com/in/ali-ahmed-b24ba4235/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <svg
              stroke="currentColor"
              fill="none"
              strokeWidth="2"
              viewBox="0 0 24 24"
              strokeLinecap="round"
              strokeLinejoin="round"
              className=" hover:text-theme-aquamarine  -ml-[19px] mb-4 hover:scale-110 hover:ease-in duration-200"
              height="1em"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M16 8a6 6 0 0 1 6 6v7h-4v-7a2 2 0 0 0-2-2 2 2 0 0 0-2 2v7h-4v-7a6 6 0 0 1 6-6z"></path>
              <rect x="2" y="9" width="4" height="12"></rect>
              <circle cx="4" cy="4" r="2"></circle>
            </svg>
          </a>
        </p>
        <div className=" h-[90px] bg-white w-[1px]"></div>
      </div>

      <div className=" fixed bottom-0 lg:right-12 sm:right-8 w-0 xxsm:hidden sm:block ">
        <a href="mailto:aliofficial3594@gmail.com ">
          <p className="font-fira   text-theme-gray-deep tracking-wider rotate-90 w-0 pb-[540px] -ml-[270px] hover:text-theme-aquamarine  transition duration-200 ease-in hover:font-black">
            aliofficial3594@gmail.com
          </p>
        </a>
        <div className=" h-[90px] bg-white w-[1px]"></div>
      </div>

      <section
        id="intro"
        className=""
        data-aos="fade-down"
        data-aos-duration="2000"
      >
        <div className="max-w-5xl mx-auto px-6 lg:px-0">
          <h4 className="text-theme-aquamarine font-fira sm:ml-[10%]  xsm:ml-4  xxsm:ml-2     xxsm:pt-40 sm:pt-44  md:pt-32 xl:pt-44 lg:mb-4  sm:mb-0 xsm:pt-32">
            Hi, my name is
          </h4>
          <h1 className="text-[#CCD6F6]  text-6xl font-Atkinson font-black sm:ml-[9%] sm:mt-4 xsm:ml-2 xsm:text-[65px] xsm:mt-2 xxsm:text-[50px]">
            Ali Ahmed
          </h1>
          <h1 className="sm:ml-[9%] text-[#CCD6F6] lg:text-[12vh] font-Atkinson font-black md:text-[75px]   xxsm:leading-[55px] xsm:leading-[68px]  sm:mt-2 xsm:text-[9.5vh] xsm:ml-2 xxsm:text-[50px] md:leading-[90px] xl:text-[10vh]">
            I'm a
            <span className="text-[#64FFDA] ml-3">Sr. Software Engineer</span> and 
            <span className="text-[#64FFDA] inline"> <img src={google}className="inline xsm:h-[9.5vh] xxsm:h-[50px] md:leading-[90px] xl:h-[10vh]" alt="google-logo"/> </span> 
            Certified
            <span className="text-[#64FFDA]">  Project Manager </span>
          </h1>
          <div className="w-full mb-4  md:mt-4 sm:w-[75%] lg:w-1/2 sm:ml-[9%] xsm:ml-3 xxsm:mt-4 xxsm:w-full">
            <p className="text-theme-gray-deep leading-relaxed ">
              Specialized in MERN Stack Development with 5+ year of professional
              experience in MERN technology. Currently working as a Sr. MERN Stack
              Developer at
              <a
                href="https://www.vvorkcloud.com/"
                className="text-theme-aquamarine ml-2 text-[15px] "
                target="_blank"
                rel="noopener noreferrer"
              >
                Vvork Cloud Technologies
              </a>
              <span> and</span>
              <a
                href="https://gdsc.community.dev/"
                className="text-theme-aquamarine ml-2 text-[15px] "
                target="_blank"
                rel="noopener noreferrer"
              >
                Google Developer Student Club (GDSC)
              </a>
            </p>
          </div>

          <a
            href="#portfolio"
            className="inline-block text-theme-aquamarine px-6   md:mt-2 lg:mt-[10px] border border-theme-aquamarine rounded sm:ml-[9%] xsm:ml-3 xxsm:py-3 hover:bg-theme-gray-light hover:bg-opacity-20"
          >
            Check out my Work !
          </a>
        </div>
      </section>
    </div>
  );
}

export default Hero;
