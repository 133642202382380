import React, { useState, useRef, useEffect } from "react";
import { Transition } from "@headlessui/react";
import LogoImg from "./assets/logo/logo.png";

function Nav() {
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef();

  useEffect(() => {
    document.body.addEventListener("click", clickOutside);

    return function cleanup() {
      window.removeEventListener("click", clickOutside);
    };
  }, []);
  let clickOutside = () => {
    const varForNav = document.getElementById("mobile-menu");
    if (varForNav) {
      setIsOpen(false);
    }
  };

  var prevScrollpos = window.pageYOffset;

  window.onscroll = function () {
    var currentScrollPos = window.pageYOffset;
    let toScrollDiv = document.querySelector("#toScroll");
    if (prevScrollpos > currentScrollPos) {
      toScrollDiv.style.position = "absolute";
      toScrollDiv.style.boxShadow = "0 0 0 0 ";
      toScrollDiv.style.backgroundColor = "#0a192f";
    } else if (currentScrollPos > prevScrollpos) {
      if (prevScrollpos > 50) {
        toScrollDiv.style.boxShadow =
          "0 10px 15px -3px rgb(0 0 0 / 0.1),0 10px 15px -3px rgb(0 0 0 / 0.1)";
        toScrollDiv.style.removeProperty("background-color");
      }
      toScrollDiv.style.position = "fixed";
    }

    prevScrollpos = currentScrollPos;
  };
  return (
    <div>
      <div className="max-w-screen-xl mx-auto font-fira" id="home">
        <div
          className=" top-0 left-0 right-0 flex items-center justify-between  md:px-5 xxsm:py-[15px] border-b-1  backdrop-opacity-10 backdrop-invert bg-theme-blue-deep/95 z-40 "
          id="toScroll"
        >
          <div>
            <a href="#home">
              <img
                className="h-10 w-10 bg-transparent xxsm:ml-8 "
                src={LogoImg}
                alt=""
                data-aos="fade-down-right"
                data-aos-duration="2000"
              />
            </a>
          </div>
          <div className="-mr-2 flex md:hidden -my-2 w-full justify-end xxsm:mr-8">
            <button
              data-aos="fade-down-right"
              data-aos-duration="2000"
              onClick={() => {
                document.querySelector("html").style.scrollBehavior = "auto";
                window.scrollTo({ top: 0 });
                let toScrollDiv = document.querySelector("#toScroll");
                toScrollDiv.style.boxShadow = "0 0 0 0 ";
                setIsOpen(!isOpen);
                document.querySelector("html").style.scrollBehavior = "smooth";
              }}
              type="button"
              className="inline-flex items-center justify-center rounded-md hover:text-theme-aquamarine hover:bg-theme-blue-base focus:outline-none focus:bg-theme-blue-base focus:text-theme-aquamarine transition duration-150 ease-in-out text-theme-aquamarine p-1"
              aria-controls="mobile-menu"
              aria-expanded="false"
            >
              {!isOpen ? (
                <svg
                  stroke="currentColor"
                  fill="none"
                  strokeWidth={2.5}
                  viewBox="0 0 24 24"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  // class="nav__icon"
                  className="h-8 w-8 -rotate-90"
                  xmlns="http://www.w3.org/2000/svg"
                  aria-hidden="true"
                >
                  <line x1="12" y1="20" x2="12" y2="10"></line>
                  <line x1="18" y1="20" x2="18" y2="4"></line>
                  <line x1="6" y1="20" x2="6" y2="16"></line>
                </svg>
              ) : (
                // <svg
                //   className="h-7 w-7"
                //   stroke="currentColor"
                //   fill="none"
                //   viewBox="0 0 24 24"
                //   aria-hidden="true"
                // >
                //   <path
                //     strokeLinecap="round"
                //     strokeLinejoin="round"
                //     strokeWidth={2}
                //     d="M4 6h16M4 12h16M4 18h16"
                //   />
                // </svg>
                <svg
                  className="h-8 w-8 block "
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              )}
            </button>
          </div>
          <div className="flex text-sm items-center">
            <a
              href="#about"
              className="text-theme-gray-light hover:text-theme-aquamarine mr-4 block xxsm:hidden md:block"
              data-aos="fade-down-right"
              data-aos-duration="3000"
            >
              <span className="text-theme-aquamarine mr-2">01.</span>
              <span>About</span>
            </a>
            <a
              href="#experience"
              className="text-theme-gray-light hover:text-theme-aquamarine mr-4 xxsm:hidden md:block "
              data-aos="fade-down-right"
              data-aos-duration="3000"
            >
              <span className="text-theme-aquamarine mr-2">02.</span>
              <span>Experience</span>
            </a>
            <a
              href="#portfolio"
              className="text-theme-gray-light hover:text-theme-aquamarine mr-4 xxsm:hidden md:block"
              data-aos="fade-down-right"
              data-aos-duration="3000"
            >
              <span className="text-theme-aquamarine mr-2">03.</span>
              <span>Portfolio</span>
            </a>
            <a
              href="#contact"
              className="text-theme-gray-light hover:text-theme-aquamarine mr-4 xxsm:hidden md:block"
              data-aos="fade-down-right"
              data-aos-duration="3000"
            >
              <span className="text-theme-aquamarine mr-2 ">04.</span>
              <span>Contact</span>
            </a>
            <a
              href="https://drive.google.com/uc?export=download&id=1iq4yDaAeBUeGKUY0ZsFGOchStn8AO5gO"
              className="text-theme-aquamarine px-4 py-2 border border-theme-aquamarine rounded  xxsm:hidden md:block hover:bg-theme-gray-light hover:bg-opacity-20 "
              data-aos="fade-down-right"
              data-aos-duration="3000"
            // target="_blank"
            // rel="noopener noreferrer"
            >
              Resume
            </a>
          </div>
        </div>
      </div>
      <div className="absolute z-20 font-fira top-0 inset-x-0 transition transform origin-top-right md:hidden">
        <div className="">
          <div className="">
            {/* this div got color for nav */}
            <div className="pt-5 pb-5 px-5 space-y-6 sm:space-y-8">
              <div className="flex items-center justify-between ">
                <div>
                  <a href="#intro">
                    <img className=" xxsm:hidden" src={LogoImg} alt="" />
                  </a>
                </div>
                <div className="-mr-2">
                  <button
                    type="button"
                    className="inline-flex items-center justify-center p-2 rounded-md text-theme-gray-deep hover:text-theme-aquamarine hover:bg-theme-blue-base focus:outline-none focus:bg-theme-blue-base focus:text-theme-aquamarine transition duration-150 ease-in-out xxsm:hidden"
                  >
                    <svg
                      className="h-6 w-6 block text-theme-aquamarine "
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M4 6h16M4 12h16M4 18h16"
                      />
                    </svg>
                    <svg
                      className="h-6 w-6 block "
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </button>
                </div>
              </div>
              <Transition
                show={isOpen}
                enter="transition ease-out duration-100 transform"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="transition ease-in duration-75 transform"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
                className="relative"
              >
                {/* this div contains nav color */}
                <div
                  className="md:hidden backdrop-opacity-10 backdrop-invert bg-theme-blue-deep/80 rounded-lg p-8 sm:h-[37vh] border-transparent border hover:border-theme-aquamarine sm:shadow-lg mt-20 "
                  id="mobile-menu"
                >
                  <nav ref={ref} className="space-y-8">
                    <div className="grid gap-7 sm:grid-cols-2 sm:row-gap-8 sm:col-gap-4 sm:relative">
                      <a
                        href="#about"
                        className="text-theme-gray-light hover:text-theme-aquamarine border p-2 rounded bg-theme-blue-deep border-theme-aquamarine hover:border-transparent"
                      >
                        <span className="text-theme-aquamarine mr-2">01.</span>
                        <span>About</span>
                      </a>
                      <a
                        href="#experience"
                        className="text-theme-gray-light hover:text-theme-aquamarine border p-2 rounded bg-theme-blue-deep border-theme-aquamarine hover:border-transparent"
                      >
                        <span className="text-theme-aquamarine mr-2">02.</span>
                        <span>Experience</span>
                      </a>
                      <a
                        href="#portfolio"
                        className="text-theme-gray-light hover:text-theme-aquamarine border p-2 rounded bg-theme-blue-deep border-theme-aquamarine hover:border-transparent"
                      >
                        <span className="text-theme-aquamarine mr-2">03.</span>
                        <span>Portfolio</span>
                      </a>
                      <a
                        href="#contact"
                        className="text-theme-gray-light hover:text-theme-aquamarine border p-2 rounded bg-theme-blue-deep border-theme-aquamarine hover:border-transparent"
                      >
                        <span className="text-theme-aquamarine mr-2">04.</span>
                        <span>Contact</span>
                      </a>
                      <a
                         href="https://drive.google.com/uc?export=download&id=1iq4yDaAeBUeGKUY0ZsFGOchStn8AO5gO"
                        className="sm:text-center text-theme-aquamarine px-4 py-2 border border-theme-aquamarine rounded sm:absolute sm:w-[50vw] sm:top-[119%] sm:left-[20%] hover:border-transparent bg-theme-blue-deep"
                        // target="_blank"
                        rel="noopener noreferrer"
                      >
                        Resume
                      </a>
                    </div>
                  </nav>
                </div>
              </Transition>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Nav;
