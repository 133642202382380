import proppu from "./assets/projects/proppu.PNG";
import pawker from "./assets/projects/pawker.PNG";
import dentistsRanked from "./assets/projects/dentistsRanked.PNG";
import getMyBoat from "./assets/projects/getMyBoat.PNG";
import techRow from "./assets/projects/techRow.PNG";
import venueMonk from "./assets/projects/venueMonk.PNG";

const projects = {
  allProjects: [
    {
      id: 1,
      title: "Venue Monk",
      des: "Service based project",
      link: "https://www.venuemonk.com/",
      lang1: "Node.Js",
      lang2: "React.Js",
      thumb: venueMonk,
    },
    {
      id: 2,
      title: "Get My Boat",
      des: "Service based project",
      link: "https://www.getmyboat.com/",
      lang1: "Node.Js",
      lang2: "React.Js",
      thumb: getMyBoat,
    },

   
    {
      id: 3,
      title: "TechRow",
      des: "Product based project",
      link: "https://www.techrow.org/",
      lang1: "React.Js",
      lang2: "Tailwind CSS",
      thumb: techRow,
    },
    {
      id: 4,
      title: "Dentists Ranked",
      des: "Service based project",
      link: "https://www.dentistsranked.com/",
      lang1: "React.Js",
      lang2: "Tailwind CSS",
      thumb: dentistsRanked,
    },

    {
      id: 5,
      title: "Pawcker",
      des: "Service based project",
      link: "https://app.pawcker.com/",
      lang1: "Next.Js",
      lang2: "Tailwind CSS",
      thumb: pawker,
    },
    {
      id: 6,
      title: "Proppu",
      des: "Service based project",
      link: "https://proppu.com/",
      lang1: "Next.Js",
      lang2: "Tailwind CSS",
      thumb: proppu,
    },
  ],
};
export default projects;
