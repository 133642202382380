import logoLoader from "./assets/logo/logoLoader.png";
import React from "react";

function Loader() {
  return (
    <div>
      <a
        href="/"
        className="main"
      
      >
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <img src={logoLoader} alt={logoLoader} className="ml-1" />
      </a>
    </div>
  );
}

export default Loader;
