import React from "react";
// import DP from "./assets/images/me.jpg";
import DP from "./assets/images/me-new.jpg";
import HTML5 from "./assets/icons/HTML5.png";
import CSS3 from "./assets/icons/CSS3.png";
import javaLogo from "./assets/icons/javaLogo.png";
import Typescript from "./assets/icons/Typescript.png";
import JQuery from "./assets/icons/JQuery.png";
import Bootstrap from "./assets/icons/Bootstrap.png";
import TailwindCSS from "./assets/icons/TailwindCSS.png";
import ReactJs from "./assets/icons/ReactJs.png";
import Redux from "./assets/icons/Redux.png";
import NextJs from "./assets/icons/NextJs.png";
import NodeJs from "./assets/icons/NodeJs.png";
import ExpressJs from "./assets/icons/ExpressJs.png";
import MongoDB from "./assets/icons/MongoDB.png";
import Postman from "./assets/icons/Postman.png";
import Git from "./assets/icons/Git.png";
import NPM from "./assets/icons/NPM.png";
import MUI from "./assets/icons/MUI.png";
import NestJs from "./assets/icons/NestJs.png";

function About() {
  return (
    <div>
      <section id="about" className="py-20 ">
        <div className="max-w-5xl  sm:ml-[9%] xsm:ml-[2%] lg:ml-[12%]  px-6 lg:px-0">
          <header className="mb-8"
            data-aos="fade-down"
            data-aos-duration="2000">
            <h3 className="text-2xl font-poppins text-theme-gray-light">
              <span className="text-theme-aquamarine mr-3 text-2xl font-fira">
                01.
              </span>
              <span className="font-bold text-3xl relative font-Atkinson">
                About Me{""}
                <div className="w-[15vw] mb-12  border absolute left-40 top-6 -z-9"></div>
              </span>
            </h3>
          </header>
          <div className="flex flex-wrap items-center "
            data-aos="fade-down"
            data-aos-duration="2000">
            <div className="w-full sm:w-[90%]  sm:order-first md:w-[60%] lg:w-[50%] text-lg ">
              <p className="text-theme-gray-deep leading-relaxed mb-3 font-Atkinson">
                Hey! My name is Ali Ahmed and I enjoy creating things that live
                on the internet. My interest in web development started when I decided to try designing , custom themes and that
                taught me a lot about HTML & CSS!
              </p>
              <p className=" leading-relaxed ">
                <svg
                  stroke="currentColor"
                  fill="currentColor"
                  strokeWidth="0"
                  viewBox="0 0 16 16"
                  className=" inline text-theme-aquamarine "
                  height=".7rem"
                  width=".7rem"
                  xmlns="ttp://www.w3.org/2000/svg"
                >
                  <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z"></path>
                </svg>
                <span className=" text-theme-gray-deep ml-4">
                  Dedicated MERN Stack developer.
                </span>
              </p>
              <p className=" leading-relaxed ">
                <svg
                  stroke="currentColor"
                  fill="currentColor"
                  strokeWidth="0"
                  viewBox="0 0 16 16"
                  className=" inline text-theme-aquamarine "
                  height=".7rem"
                  width=".7rem"
                  xmlns="ttp://www.w3.org/2000/svg"
                >
                  <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z"></path>
                </svg>
                <span className="text-theme-gray-deep  ml-4">
                  Excellent problem solving skills.
                </span>
              </p>
              <p className=" leading-relaxed ">
                <svg
                  stroke="currentColor"
                  fill="currentColor"
                  strokeWidth="0"
                  viewBox="0 0 16 16"
                  className=" inline text-theme-aquamarine "
                  height=".7rem"
                  width=".7rem"
                  xmlns="ttp://www.w3.org/2000/svg"
                >
                  <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z"></path>
                </svg>
                <span className="text-theme-gray-deep  ml-4">
                  Building highly responsive and quality web applications with
                  MERN Technologies.
                </span>
              </p>
              <p className=" leading-relaxed ">
                <svg
                  stroke="currentColor"
                  fill="currentColor"
                  strokeWidth="0"
                  viewBox="0 0 16 16"
                  className=" inline text-theme-aquamarine "
                  height=".7rem"
                  width=".7rem"
                  xmlns="ttp://www.w3.org/2000/svg"
                >
                  <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z"></path>
                </svg>
                <span className="text-theme-gray-deep  ml-4">
                  Web developer with 5+ years of well rounded experience in MERN
                  Stack Development.
                </span>
              </p>
              <p className=" leading-relaxed ">
                <svg
                  stroke="currentColor"
                  fill="currentColor"
                  strokeWidth="0"
                  viewBox="0 0 16 16"
                  className=" inline text-theme-aquamarine "
                  height=".7rem"
                  width=".7rem"
                  xmlns="ttp://www.w3.org/2000/svg"
                >
                  <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z"></path>
                </svg>
                <span className="text-theme-gray-deep  ml-4">
                  Strong project management skills, customer service, strong
                  collaborative skills.
                </span>
              </p>

              <p className="text-theme-gray-deep leading-relaxed mb-3 ">
                Here is a list of the technologies that I'm familiar with!
              </p>
              <div className="flex flex-wrap text-theme-gray-deep pt-8">
                <div className="lg:w-1/2 sm:w-[33%] md:w-1/2 w-1/2 text-sm text-theme-gray-light py-2">
                  <span className="font-black text-theme-aquamarine text-xs mr-2 inline-block">
                    <svg
                      stroke="currentColor"
                      fill="currentColor"
                      strokeWidth="0"
                      viewBox="0 0 16 16"
                      className=" inline text-theme-aquamarine mt-1"
                      height="11px"
                      width="11px"
                      xmlns="ttp://www.w3.org/2000/svg"
                    >
                      <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z"></path>
                    </svg>
                    <img
                      src={HTML5}
                      alt="javaLogo"
                      className="h-8 w-auto inline ml-2"
                    />
                  </span>
                  <h6 className="-mt-6 ml-[55px]"> HTML5 </h6>
                </div>

                <div className="lg:w-1/2 sm:w-[33%] md:w-1/2 w-1/2 text-sm text-theme-gray-light py-2">
                  <span className="font-black text-theme-aquamarine text-xs mr-2 inline-block">
                    <svg
                      stroke="currentColor"
                      fill="currentColor"
                      strokeWidth="0"
                      viewBox="0 0 16 16"
                      className=" inline text-theme-aquamarine mt-1"
                      height="11px"
                      width="11px"
                      xmlns="ttp://www.w3.org/2000/svg"
                    >
                      <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z"></path>
                    </svg>
                    <img
                      src={CSS3}
                      alt="javaLogo"
                      className="h-8 w-auto inline ml-2"
                    />
                  </span>
                  <h6 className="-mt-6 ml-[50px]"> CSS3 </h6>
                </div>
                <div className="lg:w-1/2 sm:w-[33%] md:w-1/2 w-1/2 text-sm text-theme-gray-light py-2 mt-1">
                  <span className="font-black text-theme-aquamarine text-xs mr-2 inline-block">
                    <svg
                      stroke="currentColor"
                      fill="currentColor"
                      strokeWidth="0"
                      viewBox="0 0 16 16"
                      className=" inline text-theme-aquamarine -mt-1"
                      height="11px"
                      width="11px"
                      xmlns="ttp://www.w3.org/2000/svg"
                    >
                      <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z"></path>
                    </svg>
                    <img
                      src={javaLogo}
                      alt="javaLogo"
                      className="h-6 w-auto inline ml-2"
                    />
                  </span>
                  <h6 className="-mt-6 ml-[50px]"> JavaScript </h6>
                </div>
                <div className="lg:w-1/2 sm:w-[33%] md:w-1/2 w-1/2 text-sm text-theme-gray-light py-2 mt-1">
                  <span className="font-black text-theme-aquamarine text-xs mr-2 inline-block">
                    <svg
                      stroke="currentColor"
                      fill="currentColor"
                      strokeWidth="0"
                      viewBox="0 0 16 16"
                      className=" inline text-theme-aquamarine "
                      height="11px"
                      width="11px"
                      xmlns="ttp://www.w3.org/2000/svg"
                    >
                      <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z"></path>
                    </svg>
                    <img
                      src={Typescript}
                      alt="javaLogo"
                      className="h-6 w-auto inline ml-2"
                    />
                  </span>
                  <h6 className="-mt-6 ml-[50px]"> Typescript </h6>
                </div>
                <div className="lg:w-1/2 sm:w-[33%] md:w-1/2 w-1/2 text-sm text-theme-gray-light py-2 mt-1">
                  <span className="font-black text-theme-aquamarine text-xs mr-2 inline-block">
                    <svg
                      stroke="currentColor"
                      fill="currentColor"
                      strokeWidth="0"
                      viewBox="0 0 16 16"
                      className=" inline text-theme-aquamarine "
                      height="11px"
                      width="11px"
                      xmlns="ttp://www.w3.org/2000/svg"
                    >
                      <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z"></path>
                    </svg>
                    <img
                      src={JQuery}
                      alt="javaLogo"
                      className="h-6 w-auto inline ml-2"
                    />
                  </span>
                  <h6 className="-mt-[22px] ml-[50px]"> JQuery </h6>
                </div>
                <div className="lg:w-1/2 sm:w-[33%] md:w-1/2 w-1/2 text-sm text-theme-gray-light py-2 mt-1">
                  <span className="font-black text-theme-aquamarine text-xs mr-2 inline-block">
                    <svg
                      stroke="currentColor"
                      fill="currentColor"
                      strokeWidth="0"
                      viewBox="0 0 16 16"
                      className=" inline text-theme-aquamarine "
                      height="11px"
                      width="11px"
                      xmlns="ttp://www.w3.org/2000/svg"
                    >
                      <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z"></path>
                    </svg>
                    <img
                      src={Bootstrap}
                      alt="javaLogo"
                      className="h-6 w-auto inline ml-2"
                    />
                  </span>
                  <h6 className="-mt-[22px] ml-[50px]"> Bootstrap </h6>
                </div>
                <div className="lg:w-1/2 sm:w-[33%] md:w-1/2 w-1/2 text-sm text-theme-gray-light py-2 mt-1">
                  <span className="font-black text-theme-aquamarine text-xs mr-2 inline-block">
                    <svg
                      stroke="currentColor"
                      fill="currentColor"
                      strokeWidth="0"
                      viewBox="0 0 16 16"
                      className=" inline text-theme-aquamarine "
                      height="11px"
                      width="11px"
                      xmlns="ttp://www.w3.org/2000/svg"
                    >
                      <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z"></path>
                    </svg>
                    <img
                      src={TailwindCSS}
                      alt="javaLogo"
                      className="h-5 w-auto inline ml-2"
                    />
                  </span>
                  <h6 className="-mt-[22px] ml-[60px]"> Tailwind CSS </h6>
                </div>
                <div className="lg:w-1/2 sm:w-[33%] md:w-1/2 w-1/2 text-sm text-theme-gray-light py-2 mt-1">
                  <span className="font-black text-theme-aquamarine text-xs mr-2 inline-block">
                    <svg
                      stroke="currentColor"
                      fill="currentColor"
                      strokeWidth="0"
                      viewBox="0 0 16 16"
                      className=" inline text-theme-aquamarine "
                      height="11px"
                      width="11px"
                      xmlns="ttp://www.w3.org/2000/svg"
                    >
                      <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z"></path>
                    </svg>
                    <img
                      src={ReactJs}
                      alt="javaLogo"
                      className="h-5 w-auto inline ml-2"
                    />
                  </span>
                  <h6 className="-mt-[20px] ml-[50px]"> React.Js </h6>
                </div>
                <div className="lg:w-1/2 sm:w-[33%] md:w-1/2 w-1/2 text-sm text-theme-gray-light py-2 mt-1">
                  <span className="font-black text-theme-aquamarine text-xs mr-2 inline-block">
                    <svg
                      stroke="currentColor"
                      fill="currentColor"
                      strokeWidth="0"
                      viewBox="0 0 16 16"
                      className=" inline text-theme-aquamarine "
                      height="11px"
                      width="11px"
                      xmlns="ttp://www.w3.org/2000/svg"
                    >
                      <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z"></path>
                    </svg>
                    <img
                      src={NestJs}
                      alt="javaLogo"
                      className="h-5 w-auto inline ml-2"
                    />
                  </span>
                  <h6 className="-mt-[20px] ml-[50px]"> NestJs </h6>
                </div>
                <div className="lg:w-1/2 sm:w-[33%] md:w-1/2 w-1/2 text-sm text-theme-gray-light py-2 mt-1">
                  <span className="font-black text-theme-aquamarine text-xs mr-2 inline-block">
                    <svg
                      stroke="currentColor"
                      fill="currentColor"
                      strokeWidth="0"
                      viewBox="0 0 16 16"
                      className=" inline text-theme-aquamarine "
                      height="11px"
                      width="11px"
                      xmlns="ttp://www.w3.org/2000/svg"
                    >
                      <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z"></path>
                    </svg>
                    <img
                      src={Redux}
                      alt="javaLogo"
                      className="h-5 w-auto inline ml-2"
                    />
                  </span>
                  <h6 className="-mt-[20px] ml-[50px]"> Redux </h6>
                </div>
                <div className="lg:w-1/2 sm:w-[33%] md:w-1/2 w-1/2 text-sm text-theme-gray-light py-2 mt-1">
                  <span className="font-black text-theme-aquamarine text-xs mr-2 inline-block">
                    <svg
                      stroke="currentColor"
                      fill="currentColor"
                      strokeWidth="0"
                      viewBox="0 0 16 16"
                      className=" inline text-theme-aquamarine "
                      height="11px"
                      width="11px"
                      xmlns="ttp://www.w3.org/2000/svg"
                    >
                      <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z"></path>
                    </svg>
                    <img
                      src={MUI}
                      alt="javaLogo"
                      className="h-6 w-auto inline ml-2"
                    />
                  </span>
                  <h6 className="-mt-[25px] ml-[60px]"> MUI </h6>
                </div>

                <div className="lg:w-1/2 sm:w-[33%] md:w-1/2 w-1/2 text-sm text-theme-gray-light py-2 mt-1">
                  <span className="font-black text-theme-aquamarine text-xs mr-2 inline-block">
                    <svg
                      stroke="currentColor"
                      fill="currentColor"
                      strokeWidth="0"
                      viewBox="0 0 16 16"
                      className=" inline text-theme-aquamarine "
                      height="11px"
                      width="11px"
                      xmlns="ttp://www.w3.org/2000/svg"
                    >
                      <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z"></path>
                    </svg>
                    <img
                      src={NodeJs}
                      alt="javaLogo"
                      className="h-6 w-auto inline ml-2"
                    />
                  </span>
                  <h6 className="-mt-[20px] ml-[50px]">Node.Js </h6>
                </div>

                <div className="lg:w-1/2 sm:w-[33%] md:w-1/2 w-1/2 text-sm text-theme-gray-light py-2 mt-1">
                  <span className="font-black text-theme-aquamarine text-xs mr-2 inline-block">
                    <svg
                      stroke="currentColor"
                      fill="currentColor"
                      strokeWidth="0"
                      viewBox="0 0 16 16"
                      className=" inline text-theme-aquamarine "
                      height="11px"
                      width="11px"
                      xmlns="ttp://www.w3.org/2000/svg"
                    >
                      <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z"></path>
                    </svg>
                    <img
                      src={MongoDB}
                      alt="javaLogo"
                      className="h-6 w-auto inline ml-1"
                    />
                  </span>
                  <h6 className="-mt-[21px] ml-[45px]">MongoDB </h6>
                </div>

                <div className="lg:w-1/2 sm:w-[33%] md:w-1/2 w-1/2 text-sm text-theme-gray-light py-2 mt-1">
                  <span className="font-black text-theme-aquamarine text-xs mr-2 inline-block">
                    <svg
                      stroke="currentColor"
                      fill="currentColor"
                      strokeWidth="0"
                      viewBox="0 0 16 16"
                      className=" inline text-theme-aquamarine "
                      height="11px"
                      width="11px"
                      xmlns="ttp://www.w3.org/2000/svg"
                    >
                      <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z"></path>
                    </svg>
                    <img
                      src={Postman}
                      alt="javaLogo"
                      className="h-6 w-auto inline ml-2"
                    />
                  </span>
                  <h6 className="-mt-[21px] ml-[50px]">Postman </h6>
                </div>
                <div className="lg:w-1/2 sm:w-[33%] md:w-1/2 w-1/2 text-sm text-theme-gray-light py-2 mt-1">
                  <span className="font-black text-theme-aquamarine text-xs mr-2 inline-block">
                    <svg
                      stroke="currentColor"
                      fill="currentColor"
                      strokeWidth="0"
                      viewBox="0 0 16 16"
                      className=" inline text-theme-aquamarine "
                      height="11px"
                      width="11px"
                      xmlns="ttp://www.w3.org/2000/svg"
                    >
                      <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z"></path>
                    </svg>
                    <img
                      src={Git}
                      alt="javaLogo"
                      className="h-7 w-auto inline ml-2"
                    />
                  </span>
                  <h6 className="-mt-[25px] ml-[55px]">Git </h6>
                </div>
                <div className="lg:w-1/2 sm:w-[33%] md:w-1/2 w-1/2 text-sm text-theme-gray-light py-2 mt-1">
                  <span className="font-black text-theme-aquamarine text-xs mr-2 inline-block">
                    <svg
                      stroke="currentColor"
                      fill="currentColor"
                      strokeWidth="0"
                      viewBox="0 0 16 16"
                      className=" inline text-theme-aquamarine "
                      height="11px"
                      width="11px"
                      xmlns="ttp://www.w3.org/2000/svg"
                    >
                      <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z"></path>
                    </svg>
                    <img
                      src={NPM}
                      alt="javaLogo"
                      className="h-7 w-auto inline ml-2"
                    />
                  </span>
                  <h6 className="-mt-[25px] ml-[55px]">NPM </h6>
                </div>
                <div className="lg:w-1/2 sm:w-[33%] md:w-1/2 w-1/2 text-sm text-theme-gray-light py-2 -mt-1">
                  <span className="font-black text-theme-aquamarine text-xs mr-2 inline-block">
                    <svg
                      stroke="currentColor"
                      fill="currentColor"
                      strokeWidth="0"
                      viewBox="0 0 16 16"
                      className=" inline text-theme-aquamarine "
                      height="11px"
                      width="11px"
                      xmlns="ttp://www.w3.org/2000/svg"
                    >
                      <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z"></path>
                    </svg>
                    <img
                      src={ExpressJs}
                      alt="javaLogo"
                      className="h-[45px] w-auto inline ml-2"
                    />
                  </span>
                  <h6 className="-mt-[35px] ml-[72px]">Express.Js </h6>
                </div>
                <div className="lg:w-1/2 sm:w-[33%] md:w-1/2 w-1/2 text-sm text-theme-gray-light py-2 mt-2">
                  <span className="font-black text-theme-aquamarine text-xs mr-2 inline-block">
                    <svg
                      stroke="currentColor"
                      fill="currentColor"
                      strokeWidth="0"
                      viewBox="0 0 16 16"
                      className=" inline text-theme-aquamarine "
                      height="11px"
                      width="11px"
                      xmlns="ttp://www.w3.org/2000/svg"
                    >
                      <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z"></path>
                    </svg>
                    <img
                      src={NextJs}
                      alt="javaLogo"
                      className="h-auto w-[40px] inline ml-2 bg-white"
                    />
                  </span>
                  <h6 className="-mt-[20px] ml-[67px]"> Next.Js </h6>
                </div>
              </div>
            </div>
            <div className="w-full sm:w-full md:w-[40%] flex items-center justify-center mb-6 sm:mb-0 ">
              <div className="w-1/2 sm:w-[40%] md:w-[80%] flex justify-center items-center boder lg:ml-24 xxsm:mt-8 md:mt-0 ">
                <div className="border-2 border-theme-aquamarine rounded-lg my-8 cursor-pointer group  ">
                  <img
                    src={DP}
                    className="-mt-4 -ml-4 mb-4 mr-6  rounded-lg  group-hover:ease-in group-hover:duration-300 grayscale group-hover:grayscale-0 relative group-hover:translate-x-2.5 group-hover:translate-y-2.5 "
                    alt=""
                  />
                </div>
              </div>
            </div>

          </div>
        </div>
      </section>
    </div>
  );
}

export default About;
