import React from "react";

function Contact() {
  return (
    <div>
      <section id="contact" className=" xxsm:py-20">
        <div className="max-w-5xl mx-auto px-6 lg:px-0">
          <header className="mb-4 text-center">
            <h4 className="text-theme-aquamarine text-base mb-3 font-fira">
              04. What's Next?
            </h4>
            <h3 className="text-6xl font-poppins text-theme-gray-light font-extrabold font-Atkinson xxsm:text-5xl">
              Get In Touch
            </h3>
          </header>
          <div className="flex justify-center mb-8">
            <div className="w-full md:w-1/2 xsm:w-[75%] text-center">
              <p className="text-theme-gray-deep font-Atkinson xsm:text-lg">
                Although I’m currently looking for new opportunities, my inbox
                is always open. Whether you have a question or just want to say
                hi, I’ll try my best to get back to you!
              </p>
            </div>
          </div>
          <div className="text-center">
            <a
              href="mailto:aliofficial3594@gmail.com "
              className="inline-block text-theme-aquamarine px-6 py-3 border border-theme-aquamarine rounded  hover:bg-theme-gray-light hover:bg-opacity-20 cursor-pointer"
            >
              Say Hello
            </a>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Contact;
