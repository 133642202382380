import React, { useEffect } from "react";

function Experience() {
  useEffect(() => {
    let show = document.querySelectorAll(".own");
    show.forEach((e, i) => {
      e.addEventListener("click", funClicked);
      function funClicked() {
        let clicked = i;
        funRemove();
        e.style.backgroundColor = "#112240";
        e.style.color = "#64FFDA";
        e.style.borderLeft = "2px solid #64FFDA";
        funHover(clicked);
      }
      function funRemove() {
        show.forEach((e) => {
          e.style.backgroundColor = "#0a192f";
          e.style.color = "#8892b0";
          e.style.borderLeft = "2px solid #8892b0";
        });
      }
    });
    function funHover(clicked) {
      show.forEach((e, i) => {
        if (i === clicked) {
          e.style.backgroundColor = "#112240";
          e.style.color = "#64FFDA";
          e.style.borderLeft = "2px solid #64FFDA";
        }
        e.onmouseenter = () => {
          if (i !== clicked) {
            e.style.backgroundColor = "#112240";
            e.style.color = "#64FFDA";
            e.style.borderLeft = "2px solid #64FFDA";
          }
        };
        e.onmouseleave = () => {
          if (i !== clicked) {
            e.style.backgroundColor = "#0a192f";
            e.style.color = "#8892b0";
            e.style.borderLeft = "2px solid #8892b0";
          }
        };
      });
    }
  }, []);

  // function funcMicrosoft() {
  //   const divForMicrosoft = document.querySelector(".divForMicrosoft");
  //   divForMicrosoft.style.display = "block";
  //   const divForVvork = document.querySelector(".divForVvork");
  //   divForVvork.style.display = "none";
  // }

  function funcGDSC() {
    const divForGoogleDeveloperStudentClub = document.querySelector(".divForGoogleDeveloperStudentClub");
    divForGoogleDeveloperStudentClub.style.display = "block";
    const divForVvork = document.querySelector(".divForVvork");
    divForVvork.style.display = "none";
  }
  function funcVvork() {
    const divForGoogleDeveloperStudentClub = document.querySelector(".divForGoogleDeveloperStudentClub");
    divForGoogleDeveloperStudentClub.style.display = "none";
    const divForVvork = document.querySelector(".divForVvork");
    divForVvork.style.display = "block";
  }
  return (
    <div>
      <section
        id="experience"
        className="md:py-20 xsm:ml-[2%]  md:-ml-12 lg:ml-0 sm:-ml-16 xxsm:py-0 xxsm:pb-12 lg:py-24"
      >
        <div className="max-w-5xl mx-auto px-6 lg:px-0">
          <div className="flex justify-center">
            <div className="w-full sm:w-2/3">
              <header className="mb-16"
               data-aos="fade-down"
               data-aos-duration="2000">
                <h3 className="text-2xl font-poppins text-theme-gray-light">
                  <span className="text-theme-aquamarine text-2xl font-fira mr-3">
                    02.
                  </span>
                  <span className="font-bold relative font-Atkinson text-3xl">
                    Experience
                    <div className="w-[15vw] mb-12  border absolute left-40 top-5 -z-9"></div>{" "}
                  </span>
                </h3>
              </header>
              <div
                className="flex flex-wrap "
                x-data="{currentExperience: 'vvork'}"
                data-aos="fade-down"
                data-aos-duration="2000"
              >
                <div className="w-full sm:w-1/6 mb-4 sm:mb-0 ">
                  <div className="flex flex-row sm:flex-col">
                    <a
                      href="#vvork"
                      className="border-gray-600 text-theme-gray-dark  px-auto sm:w-[160%] md:w-[120%] xxsm:px-4 md:px-0 py-3 border-b-4 sm:border-b-0 border-l-0 sm:border-l-2 text-xs hover:bg-[#112240]  text-center hover:text-theme-aquamarine font-fira font-medium mb-1 hover:border-l-theme-aquamarine 
                      bg-[#112240] text-theme-aquamarine border-l-theme-aquamarine own "
                      onClick={() => {
                        funcVvork();
                      }}
                    >
                      Vvork Cloud Technologies
                    </a>
      <a
                      href="#GoogleDeveloperStudentClub"
                      className="px-4  border-gray-600 text-theme-gray-deep 
                      hover:bg-[#112240] hover:text-theme-aquamarine hover:border-l-theme-aquamarine  px-auto sm:w-[160%] md:w-[120%] xxsm:px-4 md:px-0 py-3 border-b-4 sm:border-b-0 border-l-0 sm:border-l-2 text-xs text-center font-fira font-medium mb-1 own "
                      onClick={() => {
                        funcGDSC();
                      }}
                    >
                      Google Developer Student Club
                    </a>
                    {/* TODO: anchor to link with another job section  */}
                    {/* <a
                      href="#microsoft"
                      className="px-4  border-gray-600 text-theme-gray-deep 
                      hover:bg-[#112240] hover:text-theme-aquamarine hover:border-l-theme-aquamarine  px-auto sm:w-[160%] md:w-[120%] xxsm:px-4 md:px-0 py-3 border-b-4 sm:border-b-0 border-l-0 sm:border-l-2 text-xs text-center font-fira font-medium mb-1 own "
                      onClick={() => {
                        funcMicrosoft();
                      }}
                    >
                      Microsoft
                    </a> */}
                  </div>
                </div>
                <div className="w-full sm:w-[72%] md:w-[75%] sm:ml-12 md:ml-6 lg:ml-6">
                  <div className="px-0 sm:px-3 relative">
                    {/*Experience Item Start*/}
                    <div
                      x-show="currentExperience === 'vvork'"
                      id="vvork"
                      className="divForVvork"
                    >
                      <header className="mb-6">
                        <h3 className="text-xl font-poppins text-theme-gray-light font-bold mb-1">
                          MERN Stack Developer
                          <span className="text-theme-aquamarine mx-3">@</span>
                          <span className="text-theme-aquamarine ">
                            Vvork Cloud Technologies
                          </span>
                        </h3>
                        <p className="text-theme-gray-deep mb-3 text-xs">
                          June 2018 - Present
                        </p>
                      </header>
                      <div>
                        <p className=" leading-relaxed ">
                          <svg
                            stroke="currentColor"
                            fill="currentColor"
                            strokeWidth="0"
                            viewBox="0 0 16 16"
                            className=" inline text-theme-aquamarine "
                            height=".7rem"
                            width=".7rem"
                            xmlns="ttp://www.w3.org/2000/svg"
                          >
                            <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z"></path>
                          </svg>
                          <span className=" text-theme-gray-deep ml-4">
                            Designing user interactions on the web application
                            itself.
                          </span>
                        </p>
                        <p className=" leading-relaxed ">
                          <svg
                            stroke="currentColor"
                            fill="currentColor"
                            strokeWidth="0"
                            viewBox="0 0 16 16"
                            className=" inline text-theme-aquamarine "
                            height=".7rem"
                            width=".7rem"
                            xmlns="ttp://www.w3.org/2000/svg"
                          >
                            <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z"></path>
                          </svg>
                          <span className=" text-theme-gray-deep ml-4">
                            Creating servers and databases for the back end of
                            the software.
                          </span>
                        </p>
                        <p className=" leading-relaxed ">
                          <svg
                            stroke="currentColor"
                            fill="currentColor"
                            strokeWidth="0"
                            viewBox="0 0 16 16"
                            className=" inline text-theme-aquamarine "
                            height=".7rem"
                            width=".7rem"
                            xmlns="ttp://www.w3.org/2000/svg"
                          >
                            <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z"></path>
                          </svg>
                          <span className=" text-theme-gray-deep ml-4">
                            Testing and maintaining the responsive design of
                            applications.
                          </span>
                        </p>
                        
                        <p className=" leading-relaxed ">
                          <svg
                            stroke="currentColor"
                            fill="currentColor"
                            strokeWidth="0"
                            viewBox="0 0 16 16"
                            className=" inline text-theme-aquamarine "
                            height=".7rem"
                            width=".7rem"
                            xmlns="ttp://www.w3.org/2000/svg"
                          >
                            <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z"></path>
                          </svg>
                          <span className=" text-theme-gray-deep ml-4">
                            Developing APIs and RESTful services.
                          </span>
                        </p>
                        <p className=" leading-relaxed ">
                          <svg
                            stroke="currentColor"
                            fill="currentColor"
                            strokeWidth="0"
                            viewBox="0 0 16 16"
                            className=" inline text-theme-aquamarine "
                            height=".7rem"
                            width=".7rem"
                            xmlns="ttp://www.w3.org/2000/svg"
                          >
                            <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z"></path>
                          </svg>
                          <span className=" text-theme-gray-deep ml-4">
                            Helping with the design and development of software.
                          </span>
                        </p>
                        <p className=" leading-relaxed ">
                          <svg
                            stroke="currentColor"
                            fill="currentColor"
                            strokeWidth="0"
                            viewBox="0 0 16 16"
                            className=" inline text-theme-aquamarine "
                            height=".7rem"
                            width=".7rem"
                            xmlns="ttp://www.w3.org/2000/svg"
                          >
                            <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z"></path>
                          </svg>
                          <span className=" text-theme-gray-deep ml-4">
                            Testing and debugging software to keep it optimized.
                          </span>
                        </p>
                        <p className=" leading-relaxed ">
                          <svg
                            stroke="currentColor"
                            fill="currentColor"
                            strokeWidth="0"
                            viewBox="0 0 16 16"
                            className=" inline text-theme-aquamarine "
                            height=".7rem"
                            width=".7rem"
                            xmlns="ttp://www.w3.org/2000/svg"
                          >
                            <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z"></path>
                          </svg>
                          <span className=" text-theme-gray-deep ml-4">
                            Writing clean code for the front and back end of the
                            software.
                          </span>
                        </p>
                        <p className=" leading-relaxed ">
                          <svg
                            stroke="currentColor"
                            fill="currentColor"
                            strokeWidth="0"
                            viewBox="0 0 16 16"
                            className=" inline text-theme-aquamarine "
                            height=".7rem"
                            width=".7rem"
                            xmlns="ttp://www.w3.org/2000/svg"
                          >
                            <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z"></path>
                          </svg>
                          <span className=" text-theme-gray-deep ml-4">
                            Considering security, maintenance, scalability, and
                            more when developing.
                          </span>
                        </p>
                        <p className=" leading-relaxed ">
                          <svg
                            stroke="currentColor"
                            fill="currentColor"
                            strokeWidth="0"
                            viewBox="0 0 16 16"
                            className=" inline text-theme-aquamarine "
                            height=".7rem"
                            width=".7rem"
                            xmlns="ttp://www.w3.org/2000/svg"
                          >
                            <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z"></path>
                          </svg>
                          <span className=" text-theme-gray-deep ml-4">
                          Keeping up with technological advances to optimize their software 
                          </span>
                        </p>
                      </div>
                    </div>
                    {/*#Experience Item End*/}
                       <div
                      x-show="currentExperience === 'GoogleDeveloperStudentClub'"
                      id="GoogleDeveloperStudentClub"
                      className=" relative top-0 hidden divForGoogleDeveloperStudentClub"
                    >
                      <header className="mb-6">
                        <h3 className="text-xl font-poppins text-theme-gray-light font-bold mb-1">
                          MERN Stack Developer
                          <span className="text-theme-aquamarine mx-3">@</span>
                          <span className="text-theme-aquamarine ">
                          Google Developer Student Club
                          </span>
                        </h3>
                        <p className="text-theme-gray-deep mb-3 text-xs">
                          Sep 2022 - Present
                        </p>
                      </header>
                      <div>
                        <p className=" leading-relaxed ">
                          <svg
                            stroke="currentColor"
                            fill="currentColor"
                            strokeWidth="0"
                            viewBox="0 0 16 16"
                            className=" inline text-theme-aquamarine "
                            height=".7rem"
                            width=".7rem"
                            xmlns="ttp://www.w3.org/2000/svg"
                          >
                            <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z"></path>
                          </svg>
                          <span className=" text-theme-gray-deep ml-4">
                          Staying up-to-date with the latest technological advancements and incorporating them into the application.
                          </span>
                        </p>
                        <p className=" leading-relaxed ">
                          <svg
                            stroke="currentColor"
                            fill="currentColor"
                            strokeWidth="0"
                            viewBox="0 0 16 16"
                            className=" inline text-theme-aquamarine "
                            height=".7rem"
                            width=".7rem"
                            xmlns="ttp://www.w3.org/2000/svg"
                          >
                            <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z"></path>
                          </svg>
                          <span className=" text-theme-gray-deep ml-4">
                          Ensuring security, scalability, and maintainability are taken into consideration during development
                          </span>
                        </p>
                        <p className=" leading-relaxed ">
                          <svg
                            stroke="currentColor"
                            fill="currentColor"
                            strokeWidth="0"
                            viewBox="0 0 16 16"
                            className=" inline text-theme-aquamarine "
                            height=".7rem"
                            width=".7rem"
                            xmlns="ttp://www.w3.org/2000/svg"
                          >
                            <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z"></path>
                          </svg>
                          <span className=" text-theme-gray-deep ml-4">
                          Writing high-quality code for both the front-end and back-end of the application
                          </span>
                        </p>
                        <p className=" leading-relaxed ">
                          <svg
                            stroke="currentColor"
                            fill="currentColor"
                            strokeWidth="0"
                            viewBox="0 0 16 16"
                            className=" inline text-theme-aquamarine "
                            height=".7rem"
                            width=".7rem"
                            xmlns="ttp://www.w3.org/2000/svg"
                          >
                            <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z"></path>
                          </svg>
                          <span className=" text-theme-gray-deep ml-4">
                          Testing and debugging the application to optimize performance
                          </span>
                        </p>
                        <p className=" leading-relaxed ">
                          <svg
                            stroke="currentColor"
                            fill="currentColor"
                            strokeWidth="0"
                            viewBox="0 0 16 16"
                            className=" inline text-theme-aquamarine "
                            height=".7rem"
                            width=".7rem"
                            xmlns="ttp://www.w3.org/2000/svg"
                          >
                            <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z"></path>
                          </svg>
                          <span className=" text-theme-gray-deep ml-4">
                          Developing APIs and RESTful services for the application
                          </span>
                        </p>
                        <p className=" leading-relaxed ">
                          <svg
                            stroke="currentColor"
                            fill="currentColor"
                            strokeWidth="0"
                            viewBox="0 0 16 16"
                            className=" inline text-theme-aquamarine "
                            height=".7rem"
                            width=".7rem"
                            xmlns="ttp://www.w3.org/2000/svg"
                          >
                            <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z"></path>
                          </svg>
                          <span className=" text-theme-gray-deep ml-4">
                          Testing and maintaining the application's responsiveness and design
                          </span>
                        </p>
                      </div>
                    </div>
                    {/*#Experience Item End*/}
                    {/* TODO: to add another job section  */}
                    {/*Experience Item Start*/}
                    {/* <div
                      x-show="currentExperience === 'microsoft'"
                      id="microsoft"
                      className=" relative top-0 hidden divForMicrosoft"
                    >
                      <header className="mb-6">
                        <h3 className="text-xl font-poppins text-theme-gray-light font-bold mb-1">
                          MERN Stack Developer
                          <span className="text-theme-aquamarine mx-3">@</span>
                          <span className="text-theme-aquamarine ">
                            Microsoft
                          </span>
                        </h3>
                        <p className="text-theme-gray-deep mb-3 text-xs">
                          Dec 2021 - Present
                        </p>
                      </header>
                      <div>
                        <p className=" leading-relaxed ">
                          <svg
                            stroke="currentColor"
                            fill="currentColor"
                            strokeWidth="0"
                            viewBox="0 0 16 16"
                            className=" inline text-theme-aquamarine "
                            height=".7rem"
                            width=".7rem"
                            xmlns="ttp://www.w3.org/2000/svg"
                          >
                            <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z"></path>
                          </svg>
                          <span className=" text-theme-gray-deep ml-4">
                            Designing user interactions on the web application
                            itself.
                          </span>
                        </p>
                        <p className=" leading-relaxed ">
                          <svg
                            stroke="currentColor"
                            fill="currentColor"
                            strokeWidth="0"
                            viewBox="0 0 16 16"
                            className=" inline text-theme-aquamarine "
                            height=".7rem"
                            width=".7rem"
                            xmlns="ttp://www.w3.org/2000/svg"
                          >
                            <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z"></path>
                          </svg>
                          <span className=" text-theme-gray-deep ml-4">
                            Creating servers and databases for the back end of
                            the software.
                          </span>
                        </p>
                        <p className=" leading-relaxed ">
                          <svg
                            stroke="currentColor"
                            fill="currentColor"
                            strokeWidth="0"
                            viewBox="0 0 16 16"
                            className=" inline text-theme-aquamarine "
                            height=".7rem"
                            width=".7rem"
                            xmlns="ttp://www.w3.org/2000/svg"
                          >
                            <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z"></path>
                          </svg>
                          <span className=" text-theme-gray-deep ml-4">
                            Testing and maintaining the responsive design of
                            applications.
                          </span>
                        </p>
                        <p className=" leading-relaxed ">
                          <svg
                            stroke="currentColor"
                            fill="currentColor"
                            strokeWidth="0"
                            viewBox="0 0 16 16"
                            className=" inline text-theme-aquamarine "
                            height=".7rem"
                            width=".7rem"
                            xmlns="ttp://www.w3.org/2000/svg"
                          >
                            <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z"></path>
                          </svg>
                          <span className=" text-theme-gray-deep ml-4">
                            Testing and maintaining the responsive design of
                            applications.
                          </span>
                        </p>
                        <p className=" leading-relaxed ">
                          <svg
                            stroke="currentColor"
                            fill="currentColor"
                            strokeWidth="0"
                            viewBox="0 0 16 16"
                            className=" inline text-theme-aquamarine "
                            height=".7rem"
                            width=".7rem"
                            xmlns="ttp://www.w3.org/2000/svg"
                          >
                            <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z"></path>
                          </svg>
                          <span className=" text-theme-gray-deep ml-4">
                            Developing APIs and RESTful services.
                          </span>
                        </p>
                        <p className=" leading-relaxed ">
                          <svg
                            stroke="currentColor"
                            fill="currentColor"
                            strokeWidth="0"
                            viewBox="0 0 16 16"
                            className=" inline text-theme-aquamarine "
                            height=".7rem"
                            width=".7rem"
                            xmlns="ttp://www.w3.org/2000/svg"
                          >
                            <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z"></path>
                          </svg>
                          <span className=" text-theme-gray-deep ml-4">
                            Helping with the design and development of software.
                          </span>
                        </p>
                        <p className=" leading-relaxed ">
                          <svg
                            stroke="currentColor"
                            fill="currentColor"
                            strokeWidth="0"
                            viewBox="0 0 16 16"
                            className=" inline text-theme-aquamarine "
                            height=".7rem"
                            width=".7rem"
                            xmlns="ttp://www.w3.org/2000/svg"
                          >
                            <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z"></path>
                          </svg>
                          <span className=" text-theme-gray-deep ml-4">
                            Testing and debugging software to keep it optimized.
                          </span>
                        </p>
                        <p className=" leading-relaxed ">
                          <svg
                            stroke="currentColor"
                            fill="currentColor"
                            strokeWidth="0"
                            viewBox="0 0 16 16"
                            className=" inline text-theme-aquamarine "
                            height=".7rem"
                            width=".7rem"
                            xmlns="ttp://www.w3.org/2000/svg"
                          >
                            <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z"></path>
                          </svg>
                          <span className=" text-theme-gray-deep ml-4">
                            Writing clean code for the front and back end of the
                            software.
                          </span>
                        </p>
                        <p className=" leading-relaxed ">
                          <svg
                            stroke="currentColor"
                            fill="currentColor"
                            strokeWidth="0"
                            viewBox="0 0 16 16"
                            className=" inline text-theme-aquamarine "
                            height=".7rem"
                            width=".7rem"
                            xmlns="ttp://www.w3.org/2000/svg"
                          >
                            <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z"></path>
                          </svg>
                          <span className=" text-theme-gray-deep ml-4">
                            Considering security, maintenance, scalability, and
                            more when developing.
                          </span>
                        </p>
                      </div>
                    </div> */}
                    {/*#Experience Item End*/}
                    {/*Experience Item Start*/}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Experience;
